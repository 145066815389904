export default class SpinnerBlocker {

    constructor() {
        this.$element = null;
    }   

    update(message) {
        var self = this;
        if(self.$element) {
            self.$element.find('h4').html(message);
        }
    }

    //Blockout the target element
    block(message, show_spinner = 1, style) {	
        var self = this;

		if(!message) message = 'Loading...';

		if(self.$element === null) {
            self.$element = $(`
                <div class="spinner-blocker ${style}-blocker">
                    <div class="spinner-blocker__loader">
                        ${show_spinner ? '<div uk-spinner="ratio:2"></div>' : ''}
                        <h4 class="spinner-blocker__message">${message}</h4>
                    </div>
                </div>
            `);

    		self.$element.appendTo('body');
            
    	} else {
            show_spinner === 1 ? self.$element.find('.uk-spinner').show() : self.$element.find('.uk-spinner').hide();
            self.$element.find('h4').html(message);
        }
		self.$element.addClass('spinner-blocker--show');
    }


    //hide the blocker
    unblock() {
    	if(this.$element !== null) {
    		this.$element.removeClass('spinner-blocker--show');
    	}
	}

    destroy() {
        var self = this;
    	if(self.$element !== null) {
    		self.$element.remove();
    	}
    }
}
