$(document).ready(function() {

    /*  JS to control the behaviour of the sidebar menu
        If on a small screen it will be absolute but on a screen larger than
        the menu it will be fixed
    */

    const $sideToolbar = $('#side-toolbar');
    setTimeout( () => {
        sidebar();
    },1000); 
    
    $(window).on('resize', () => {
        if (!$sideToolbar.hasClass('open')) {
            sidebar();
        }
    });

    function sidebar() {
        const $sidebar_height = $sideToolbar.find('ul').first();
        const window_height = $(window).height();
        const doc_height = $(document).height();

        $sidebar_height.css({'height': 'auto'});

        if($sidebar_height.height() > window_height) {
            $sideToolbar.attr('class', 'absolute').height(doc_height);
        } else {
            $sideToolbar.attr('class', 'fixed').height(window_height);
        }

        $sidebar_height.height(doc_height);
    }

    /*
        Sidebar menu toggles
    */
    $sideToolbar.on('mouseenter',
        (e) => {
            if($(window).width() > 680) {
                $(e.currentTarget).addClass('open');
            }
        }
    );
    $sideToolbar.on('mouseleave',
        (e) => {
            if($(window).width() > 680) {
                $(e.currentTarget).removeClass('open');
            }
        }
    );


    // Toggle sub menus on small screens
    $(document).on('click', '#side-toolbar .mobile-icon-toggle', (e) => {
        $(e.currentTarget).closest('li').find('ul').slideToggle();
        return false;
    });

    $(document).on('click', '#side-toolbar a:not(#menu-toggle)', (e) => {
        $sideToolbar.find('li').removeClass('active');
        $(e.currentTarget).parent().addClass('active');
        $sideToolbar.removeClass('open');
        $('html').removeClass('open');
    });


    $(document).on('click', '#mobile-menu-toggle,#side-toolbar-wrap button', () =>{
        $sideToolbar.toggleClass('open');
    });

   
    /*
        Triggers the account dropdown
    */
    $(document).on('click', '.dropdown-trigger', (e) => {
        const target = $(e.currentTarget).attr('data-target');
        $('[data-id="' + target + '"]').fadeToggle();
    });

    /*
       Tabs
    */

    $(document).on('click', '.nav-tabs li:not(.more, #nav-gantt-chart)', (e) => {
        const connect = $(this).parent().attr('data-connect');
        const target = $(this).attr('data-target');
        $(e.currentTarget).parent().find('a').removeClass('active');
        $(e.currentTarget).find('a').addClass('active');

        $('#' + connect + ' div').removeClass('active');
        $('#' + connect).find('[data-id="' + target + '"]').addClass('active');
    });


    $(document).on('click', '[data-toggle="tab"]', (e) => {
        e.preventDefault();
        window.location.hash = $(e.currentTarget).attr('href');
        
        $(e.currentTarget).closest('.nav-tabs').find('li').removeClass('active');
        $('.tab-pane').removeClass('active');
        $($(e.currentTarget).attr('href')).addClass('active');
        $(e.currentTarget).closest('li').addClass('active');
    }); 

    // Menu Offscreen check
    $("#side-toolbar ul li").on("mouseenter", _.debounce(checkMenuHeight, 100));
    $("#side-toolbar ul li").on("mouseleave", _.debounce(resetMenuHeight, 100));
    $(window).on('resize', resetMenuHeight);

    function resetMenuHeight() {
        const $target = $(this).find('ul').first();

        if ($target.length > 0 && typeof $target !== "undefined") {
            $target.css('bottom', 'auto').removeClass('is-offscreen');
            $(this).css({ 'position': 'relative' });
        }
    }

    function checkMenuHeight() {
        const $target = $(this).find('ul').first();

        if ($('#side-toolbar').hasClass('fixed')) {

            if ($target.length > 0 && typeof $target !== "undefined") {
                $target.css('bottom', 'auto').removeClass('is-offscreen');
                const window_height = $(window).height();
                const target_height = $target.outerHeight();
                const target_top = $target.offset().top - $(window).scrollTop();

                if (target_height + target_top > window_height) {
                    $target.css({ 'bottom': 30 }).addClass('is-offscreen');
                    $(this).css({ 'position': 'static' });
                } else {
                    $target.css('bottom', 'auto').removeClass('is-offscreen');
                    $(this).css({ 'position': 'static' });
                }
            }
        }
    }
});