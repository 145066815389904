import $ from 'jquery';
import Handlebars from 'handlebars';

(function($) {
    Handlebars.registerHelper('log', function(variable) {
        console.log("######## LOGGING START ##########");
        console.log(variable)
        console.log("########");
    });

    Handlebars.registerHelper('default', function(options) {
        if (typeof options['hash']['value'] === 'string' && options['hash']['value'].length > 0) {
            return options['hash']['value'];
        } else {
            return options['hash']['emptyValue'];
        }

    });

    Handlebars.registerHelper('arrayToDelimitedString', function(array, property, delimiter) {
        if(Array.isArray(array)) {
            return array.map(function(n) {
                if(property) {
                    return n[property];
                } else {
                    return n;
                }
            }).join(delimiter);
        }
    });
    Handlebars.registerHelper('momentDateFormat', function(dateTime, format) {
        return moment(dateTime).format(format);
    });

    Handlebars.registerHelper('var', function(name, value, context) {
        this[name] = value;
    });


    Handlebars.registerHelper('truncate', function(str, len) {
        if (str.length > len && str.length > 0) {
            var new_str = str + " ";
            new_str = str.substr(0, len);
            new_str = str.substr(0, new_str.lastIndexOf(" "));
            new_str = (new_str.length > 0) ? new_str : str.substr(0, len);

            return new_str;
        }
        return str;
    });


    Handlebars.registerHelper('ucFirst', function(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    });

    Handlebars.registerHelper('if_eq', function(a, b, opts) {
        if (a == b) // Or === depending on your needs
            return opts.fn(this);
        else
            return opts.inverse(this);
    });
    Handlebars.registerHelper('if_and', function(a, b, opts) {
        if (a && b) {
            return opts.fn(this);
        } 
        return opts.inverse(this);
    });
    Handlebars.registerHelper('if_not_eq', function(a, b, opts) {
        if(a != b)
            return opts.fn(this);
        else
            return opts.inverse(this);
    });

    Handlebars.registerHelper('if_neq', function(a, b, opts) {
        if (a != b) // Or === depending on your needs
            return opts.fn(this);
        else
            return opts.inverse(this);
    });

    Handlebars.registerHelper('lowercase', function(str) {
        if (!str) return "";
        return str.toLowerCase();
    });



    Handlebars.registerHelper('filename', function(file) {
        if (!file) return "";
        var t = file.split(".");
        return t.pop().toLowerCase();
    });


    Handlebars.registerHelper('is_video', function(file, opts) {
        if (!file) return "";
        var file = file.split(".");
        var file = file.pop().toLowerCase();

        if (file === "mp4") {
            return opts.fn(this);
        } else {
            return opts.inverse(this);
        }
    });

    Handlebars.registerHelper('checkimage', function(file) {
        if (!file) return "";
        var t = file.split(".");
        var file = t.pop().toLowerCase();

        if (file === "jpg" || file === "jpeg" || file === "png" || file === "gif") {
            return 1;
        } else {
            return 0;
        }
    });


    Handlebars.registerHelper('is_image', function(file) {
        if (!file) return "";
        var t = file.split(".");
        var file = t.pop().toLowerCase();

        if (file === "jpg" || file === "jpeg" || file === "png" || file === "gif") {
            return 1;
        } else {
            return 0;
        }
    });

    Handlebars.registerHelper('time_helper', function(str) {
        if (!str) return "";
        var t = str.split(" ");
        return t[0];
    });

    // Json
    Handlebars.registerHelper('json', function(context) {
        return JSON.stringify(context);
    });


    Handlebars.registerHelper("formatDate", function(datetime, part) {
        var parts = datetime.split(' ');
        var date = parts[0].split('-');

        if (part == "year") {
            return date[0];
        } else if (part == "month") {
            return date[1];
        } else if (part == "day") {
            return date[2];
        } else if (part == "time") {
            return parts[1];
        }
    });

    Handlebars.registerHelper("onlyDate", function(datetime) {
        var parts = datetime.split(' ');
        return parts[0];
    });


    Handlebars.registerHelper("blogDate", function(datetime) {
        var parts = datetime.split(' ');
        date = parts[0].split('-');

        var date = moment(datetime).format('D MMM');
        date += "<br />";
        date += moment(datetime).format('YYYY');
        return date;
    });


    Handlebars.registerHelper("blogDateSimple", function(datetime) {
        var parts = datetime.split(' ');
        date = parts[0].split('-');

        var date = moment(datetime).format('D MMM');
        date += " ";
        date += moment(datetime).format('YYYY');
        return date;
    });


    Handlebars.registerHelper("timeSince", function(date) {

        var date = new Date(Date.now() - date);

        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            return interval + " years ago";
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            return interval + " months ago";
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            return interval + " days ago";
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            return interval + " hours ago";
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            return interval + " minutes ago";
        }
        return Math.floor(seconds) + " seconds ago";
    });





    Handlebars.registerHelper('if_even', function(conditional, options) {
        if ((conditional % 2) == 0) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }
    });


    Handlebars.registerHelper('get_length', function(obj) {
        return obj.length;
    });


    Handlebars.registerHelper('times', function(n, block) {
        var accum = '';
        for (var i = 0; i < n; ++i)
            accum += block.fn(i);
        return accum;
    });


    Handlebars.registerHelper('var', function(name, value, context) {
        this[name] = value;
    });


    Handlebars.registerHelper('grouped_each', function(every, context, options) {
        var out = "",
            subcontext = [],
            i;
        if (context && context.length > 0) {
            for (i = 0; i < context.length; i++) {
                if (i > 0 && i % every === 0) {
                    out += options.fn(subcontext);
                    subcontext = [];
                }
                subcontext.push(context[i]);
            }
            out += options.fn(subcontext);
        }
        return out;
    });

    Handlebars.registerHelper('compare', function(lvalue, rvalue, options) {

        if (arguments.length < 3)
            throw new Error("Handlerbars Helper 'compare' needs 2 parameters");

        var operator = options.hash.operator || "==";

        var operators = {
            '==': function(l, r) {
                return l == r;
            },
            '===': function(l, r) {
                return l === r;
            },
            '!=': function(l, r) {
                return l != r;
            },
            '<': function(l, r) {
                return l < r;
            },
            '>': function(l, r) {
                return l > r;
            },
            '<=': function(l, r) {
                return l <= r;
            },
            '>=': function(l, r) {
                return l >= r;
            },
            'typeof': function(l, r) {
                return typeof l == r;
            }
        }

        if (!operators[operator])
            throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);

        var result = operators[operator](lvalue, rvalue);

        if (result) {
            return options.fn(this);
        } else {
            return options.inverse(this);
        }

    });


    Handlebars.registerHelper("clean", function(str) {
        if (str && typeof str === 'string') {
            str = str.split(" ").join("-");
            str = str.replace(/\//g, "-")
            return str.replace(/-+$/, '').toLowerCase();
        }
    });

    Handlebars.registerHelper("remove_line_breaks", function(str) {
        if (typeof str !== "undefined") {
            return str.toString().replace(/([^>\r\n]?)(\r\n|\n\r|\r|\\n)/g, '');
        }
    });

    Handlebars.registerHelper("strip_tags", function(str) {
        if (typeof str !== "undefined") {
            var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

            return str.replace(commentsAndPhpTags, "").replace(tags, "");
        }
    });

    Handlebars.registerHelper("render_text", function(str) {
        if (typeof str !== "undefined") {
            return str.replace(/<(?:.|\n)*?>/gm, '');
        }
    });


    Handlebars.registerHelper("math", function(lvalue, operator, rvalue, round) {
        lvalue = parseFloat(lvalue);
        rvalue = parseFloat(rvalue);

        if (round === "round") {

            return {
                "+": Math.round(lvalue + rvalue),
                "-": Math.round(lvalue - rvalue),
                "*": Math.round(lvalue * rvalue),
                "/": Math.round(lvalue / rvalue),
                "%": Math.round(lvalue % rvalue)
            }[operator];
        } else {
            return {
                "+": lvalue + rvalue,
                "-": lvalue - rvalue,
                "*": lvalue * rvalue,
                "/": lvalue / rvalue,
                "%": lvalue % rvalue
            }[operator];
        }
    });

    Handlebars.registerHelper('json', function(context) {
        return JSON.stringify(context);
    });

     Handlebars.registerHelper('split', function(str, separator) {
        if(typeof str !=="undefined") {
            var parts = str.split(separator);

            if(parts[0]) {
                return parts[0];
             } else {
                return str;
             } 
        }
    });



    Handlebars.registerHelper("pagination", function(currentPage, nextPage, pageCount, itemShow) {

        currentPage = parseInt(currentPage);
        pageCount = parseInt(pageCount);
        itemShow = parseInt(itemShow);


        var pagination = "";
        var active = "";

        if (pageCount == 1) {
            pagination += '<li><a href="#" class="active btn btn-mini btn-outline toggle toggle-all" data-id="1">1</a></li>';
        } else if (pageCount <= itemShow) {
            for (var i = 1; i <= pageCount; i++) {
                active = (i == currentPage) ? "active" : "";
                pagination += '<li><a href="#" class="' + active + ' btn btn-mini btn-outline toggle toggle-all" data-id="' + i + '">' + i + '</a></li>';
            }
        } else if (pageCount > itemShow) {

            var gapCount = pageCount - itemShow;

            // 1 ... 3 4 5 6
            if (gapCount == 1) {
                pagination += '<li><a href="#" class="btn btn-mini btn-outline toggle toggle-all" data-id="1">1</a></li> ... ';
                for (var i = (pageCount - itemShow); i <= (itemShow - 1); i++) {
                    active = (i == currentPage) ? "active" : "";
                    pagination += '<li><a href="#" class="' + active + ' btn btn-mini btn-outline toggle toggle-all" data-id="' + i + '">' + i + '</a></li>';
                }
            }
            // 1 2 3 4 ... 100
            else if ((gapCount >= itemShow) && (currentPage < itemShow - 1)) {
                for (var i = 1; i <= itemShow - 1; i++) {
                    active = (i == currentPage) ? "active" : "";
                    pagination += '<li><a href="#" class="' + active + ' btn btn-mini btn-outline toggle toggle-all" data-id="' + i + '">' + i + '</a></li>';
                }
                pagination += ' ... <li><a href="#" class="btn btn-mini btn-outline toggle toggle-all" data-id="' + pageCount + '">' + pageCount + '</a></li>';
            }

            // 1 ... 3 4 5 ... 100
            else if ((gapCount >= itemShow) && (pageCount > itemShow + 2) && currentPage !==1) {
                pagination += '<li><a href="#" class="btn btn-mini btn-outline toggle toggle-all" data-id="1">1</a></li> ... ';

                if (pageCount - currentPage > itemShow - 3) {
                    for (var i = currentPage - 1; i <= currentPage + 1; i++) {
                        active = (i == currentPage) ? "active" : "";
                        pagination += '<li><a href="#" class="' + active + ' btn btn-mini btn-outline toggle toggle-all" data-id="' + i + '">' + i + '</a></li>';
                    }
                } else {
                    for (var i = pageCount - itemShow + 2; i <= pageCount; i++) {
                        active = (i == currentPage) ? "active" : "";
                        pagination += '<li><a href="#" class="' + active + ' btn btn-mini btn-outline toggle toggle-all" data-id="' + i + '">' + i + '</a></li>';
                    }
                }

                if ((pageCount != currentPage) && (pageCount - currentPage > itemShow - 3)) {
                    pagination += ' ... <li><a href="#" class="btn btn-mini btn-outline toggle toggle-all" data-id="' + pageCount + '">' + pageCount + '</a></li>';
                }
            }

            // 1 ... 97 98 99 100
            else if ((gapCount > itemShow) && (pageCount - currentPage <= itemShow - 1)) {
                pagination += '<li><a href="#" class="btn btn-mini btn-outline toggle toggle-all" data-id="1">1</a></li> ... ';
                for (var i = (pageCount - itemShow + 1); i <= (itemShow - 1); i++) {
                    active = (i == currentPage) ? "active" : "";
                    pagination += '<li><a href="#" class="' + active + ' btn btn-mini btn-outline toggle toggle-all" data-id="' + i + '">' + i + '</a></li>';
                }
            } else {
                if (pageCount - currentPage > itemShow - 2) {
                    for (var i = 1; i <= itemShow - 1; i++) {
                        active = (i == currentPage) ? "active" : "";
                        pagination += '<li><a href="#" class="' + active + ' btn btn-mini btn-outline toggle toggle-all" data-id="' + i + '">' + i + '</a></li>';
                    }
                } else {
                    pagination += '<li><a href="#" class="btn btn-mini btn-outline toggle toggle-all" data-id="1">1</a></li> ... ';
                    for (var i = pageCount - itemShow + 2; i <= pageCount; i++) {
                        active = (i == currentPage) ? "active" : "";
                        pagination += '<li><a href="#" class="' + active + ' btn btn-mini btn-outline toggle toggle-all" data-id="' + i + '">' + i + '</a></li>';
                    }
                }

                if ((pageCount != currentPage) && (pageCount - currentPage > itemShow - 2)) {
                    pagination += ' ... <li><a href="#" class="btn btn-mini btn-outline toggle toggle-all" data-id="' + pageCount + '">' + pageCount + '</a></li>';
                }
            }
        } else {
            pagination += '<li><a href="#" class="active btn btn-mini btn-outline toggle toggle-all" data-id="0">0</a></li>';
        }

        return pagination;
    });
})(jQuery);


function handleErrors(errors, options) {

    utilities.dismissNotifications();

    utilities.notify($.extend({
        message: '<ul class="stacked clearfix">' + errors.map(function(e) {
            return '<li class="clearfix">' + e + '</li>';
        }) + '</ul>',
        status: 'error',
        timeout: 0,
        pos: 'top-center'
    }, options));
}