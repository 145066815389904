import "@babel/polyfill";
import 'url-search-params-polyfill';
import trim from 'lodash/trim';

window.$ = window.jQuery = jQuery;

import Handlebars from 'handlebars';
window.Handlebars = Handlebars;

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

window.UIkit = UIkit;
    
import Tippy from 'tippy.js';
window.Tippy = Tippy;

import 'jQuery_HandlebarsJS/js/functions/jquery.loadFromTemplate.js';
require('handlebars-form-helpers').register(Handlebars);
require('jquery-match-height');
import 'mixitup/src/jquery.mixitup.js';
import 'jquery-confirm/';
import 'select2';
import pickadate from 'pickadate/lib/picker.date.js';

require('jquery-migrate');

import _ from 'underscore';
window._ = _;

import 'flatpickr';

// import moment from 'moment';

import moment from 'moment-timezone';
window.moment = moment;


import qq from 'fine-uploader/fine-uploader/fine-uploader.js';
window.qq = qq;

import './helpers.js';
import './comments.js';
import './tools.js';
import './layout.js';

import SpinnerBlocker from './spinner-blocker';
window.spinnerBlocker = new SpinnerBlocker();

import utilities from './utilities.js';
window.utilities = utilities;

/** Aliasing deprecated jquery functions */
$.trim = (str) => trim(str);
$.isArray = Array.isArray;